import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Page from './Page';
import Poster from './images/rebound-rad-ride/24/logo.jpg'


const HomePage = () => {
    return (
        <Page sx={{height: '100vh', display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', height: 'auto'}} component="img" src={Poster} alt="Rad Ride Raffle Logo" />
            <Button variant="contained" size="large" href="https://camba.ca/rad-ride-raffle" sx={{mt:4}}>Buy Tickets</Button>
        </Page>
    )
}

export default HomePage;
