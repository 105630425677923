import {useEffect, useState} from 'react';
import {getFunctions, httpsCallable} from 'firebase/functions';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import Logo from './images/rebound-rad-ride/24/logo.jpg'
import Page from './Page';
import Button from '@mui/material/Button';

const RAFFLE_ID = 'rebound-rad-ride-24';

const ReportPage = () => {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    httpsCallable(getFunctions(), 'report')({
      raffle: RAFFLE_ID,
    })
        .then(result => {
          if (result.data.status === 'ok') {
            setInfo(result.data.payload);
          }
          else {
            setError(result.data);
          }
        })
        .catch(setError)
  }, []);


  const soldTickets = type => {
    return Object.values(info.tickets[type]).reduce((sum, it) => {
      return sum + it.redeemed;
    }, 0);
  };
  const totalTickets = type => {
    return Object.values(info.tickets[type]).reduce((sum, it) => {
      return sum + it.total;
    }, 0);
  };

  const exportDraws = () => {
    setIsExporting(true);

    httpsCallable(getFunctions(), 'export')({
      raffle: RAFFLE_ID,
    })
        .then(result => {
          if (result.data.status === 'ok') {
            window.open(result.data.export, '_blank').focus();
          }
          else {
            setError(result.data);
          }
        })
        .catch(setError)
        .finally(() => setIsExporting(false));
  }

  return (
      <Page>
        {!info && !error && <CircularProgress/>}
        {error && <Alert severity="error" >
          <AlertTitle>Oops, an error occurred.</AlertTitle>
          <pre>
            {JSON.stringify(error, null, 2)}
          </pre>
        </Alert>}
        {info &&
          <Stack gap={2} alignItems="center">
            <Box sx={{width: "512px", height: 'auto', maxWidth: "100%"}} component="img" src={Logo} alt="Rad Ride Raffle Logo" />
            <Typography color="primary" fontWeight={900} sx={{
              fontSize: {
                xs: "6rem",
                sm: "8rem",
                md: "10rem"
              }
            }}>${info.total}</Typography>
            <Grid container spacing={1} sx={{width:"100%"}}>
              {Object.keys(info.tickets).map(type => <Grid item key={type} xs={12} md={6} >
                <Card variant="outlined">
                  <CardHeader avatar={<Avatar><LocalActivityIcon/></Avatar>} title={type}
                              subheader={`${soldTickets(type)} / ${totalTickets(type)} Sold`}
                  />
                  <Stack alignItems="flex-end">
                    <Typography variant="caption" sx={{pr:0.5}}>{totalTickets(type) - soldTickets(type)} left</Typography>
                    <LinearProgress value={soldTickets(type) / totalTickets(type) * 100} variant="determinate" sx={{height: 32, width: '100%'}}/>
                  </Stack>
                </Card>
              </Grid>)}
            </Grid>
            <Button variant="outlined" onClick={exportDraws} disabled={isExporting} startIcon={isExporting?<CircularProgress size={24}/>:null}>Export Draws</Button>
          </Stack>
        }
        {error && <Alert severity="error" sx={{mt:2}}>
          <AlertTitle>Oops, an error occurred.</AlertTitle>
          <pre>
            {JSON.stringify(error, null, 2)}
          </pre>
        </Alert>}
      </Page>
  )
};

export default ReportPage;