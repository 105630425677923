import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import HomePage from './HomePage';
import RedeemPage from './RedeemPage';
import ReportPage from './ReportPage';

const firebaseConfig = {
    apiKey: "AIzaSyBlc6PRoow0DwbA5WrV1UEvrZV9AWqPAlU",
    authDomain: "camba-raffles.firebaseapp.com",
    projectId: "camba-raffles",
    storageBucket: "camba-raffles.appspot.com",
    messagingSenderId: "142009967757",
    appId: "1:142009967757:web:0c977afb7fc39db4672ec4"
};


const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);

const useEmulator = false;
if (useEmulator && window.location.hostname === "localhost") {
    console.debug("Connecting to Firebase emulator");
    connectAuthEmulator(auth, "http://localhost:9001");
    connectFunctionsEmulator(getFunctions(app), "localhost", 9002);
    connectFirestoreEmulator(firestore, 'localhost', 9003);
    connectStorageEmulator(getStorage(app), "localhost", 9006);
}

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/redeem/:code" element={<RedeemPage />} />
                <Route path="/report" element={<ReportPage />} />
            </Routes>
        </Router>
    );
}

export default App;
