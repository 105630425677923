import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const Page = ({children, ...props}) => {
    return (
        <Container maxWidth="sm" {...props}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{
              py: {
                xs: 2,
                sm: 4,
                md: 8
              }
            }}>
                {children}
            </Box>
        </Container>
    )
}

export default Page;
